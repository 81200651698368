import React from "react"
import styled from "styled-components"

import { media } from "../utils/media"
import config from "../../config/SiteConfig"

const HeaderWrapper: any = styled.header`
    position: relative;
    background-size: cover;
    padding: 2rem 1rem 5rem;
    text-align: center;

    @media ${media.tablet} {
        padding: 2rem 1rem 5rem;
    }
    @media ${media.phone} {
        padding: 1rem 1rem 3rem;
    }
`

const Content = styled.div`
    position: relative;
    z-index: 999;
    a {
        color: white;
        &:hover {
            opacity: 0.85;
            color: white;
        }
    }
`

interface Props {
    children: any
    banner?: string
}

export class Header extends React.PureComponent<Props> {
    public render() {
        return (
            <HeaderWrapper banner={this.props.banner || config.defaultBg}>
                <Content>{this.props.children}</Content>
            </HeaderWrapper>
        )
    }
}
