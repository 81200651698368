export default {
    pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

    siteTitle: "Tyler Churchill | Full Stack Developer", // Navigation and Site Title
    siteTitleAlt: "Tyler Churchill | Full Stack Developer", // Alternative Site title for SEO
    siteUrl: "https://tylerchurchill.com", // Domain of your site. No trailing slash!
    siteLanguage: "en", // Language Tag on <html> element
    siteBanner: "/assets/banner.jpg", // Your image for og:image tag. You can find it in the /static folder
    defaultBg: "/assets/bg.jpg", // default post background header
    favicon: "src/favicon.png", // Your image for favicons. You can find it in the /src folder
    siteDescription: "Full Stack Developer from Calgary, Alberta, Canada", // Your site description
    author: "Tyler Churchill", // Author for schemaORGJSONLD
    siteLogo: "/assets/logo.png", // Image for schemaORGJSONLD

    // siteFBAppID: '123456789', // Facebook App ID - Optional
    userTwitter: "@tyler_churchill", // Twitter Username - Optional
    ogSiteName: "tylerchurchill", // Facebook Site Name - Optional
    ogLanguage: "en_US", // Facebook Language

    // Manifest and Progress color
    // See: https://developers.google.com/web/fundamentals/web-app-manifest/
    themeColor: "#3498DB",
    backgroundColor: "#2b2e3c",

    // Settings for typography.ts
    headerFontFamily: "Bitter",
    bodyFontFamily: "Open Sans",
    baseFontSize: "18px",

    // Social media
    siteFBAppID: "",

    //
    Google_Tag_Manager_ID: "GTM-PB7L4Q5",
    POST_PER_PAGE: 4,
}
