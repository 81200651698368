import styled from "styled-components"
import Theme from "../../config/Theme"
import { media } from "../utils/media"

export const Wrapper: any = styled.div`
    color: ${Theme.colors.white};
    h1, h2, h3 {
        color: ${Theme.colors.grey.dark};
    }
    margin: 0 auto;
    max-width: ${(props: any) => (props.fullWidth ? "100%" : "100rem")};
    padding: ${(props: any) => (props.fullWidth ? "0" : "0 3rem")};
    @media ${media.tablet} {
        padding: ${(props: any) => (props.fullWidth ? "0" : "0 3rem")};
    }
    @media ${media.phone} {
        padding: ${(props: any) => (props.fullWidth ? "0" : "0 1rem")};
    }
    ^permalink {
        ${(props: any) => (props.theme.colors.dark)};
    }
`
