const colors = {
    primary: "#371BB1", // Color for buttons or links
    bg: "#12151F", // Background color
    white: "#eee",
    grey: {
        dark: "#05F4B7",
        default: "rgba(233, 225, 204, 1)",
        light: "#05F4B7",
        ultraLight: "#05F4B7",
    },
}

const transitions = {
    normal: "0.5s",
}

const fontSize = {
    small: "0.9rem",
    big: "2rem",
}

export default {
    colors,
    transitions,
    fontSize,
}
